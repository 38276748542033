import React from 'react'
import CollectionsGrid from '../components/CollectionsGrid/CollectionsGrid'
import SEO from '../components/SEO/SEO'

export default function WorksPage() {
  return (
    <>
      <SEO title="Works" route="/works"></SEO>
      <CollectionsGrid />
    </>
  )
}
